import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { SidenavService, Submenu } from './sidenav.service';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  @ViewChild('sidenav') sidenav: MatSidenav;

  SUBMENU_CPLC_REF = Submenu.CPLC;
  SUBMENU_ARTISTES_REF = Submenu.ARTISTES;
  SUBMENU_CONCERTS_REF = Submenu.CONCERTS;
  SUBMENU_VINGTIEME_REF = Submenu.VINGTIEME;
  SUBMENU_MEMBRES_REF = Submenu.MEMBRES;

  title = 'CPLC';

  activeSubmenu: Submenu;

  constructor(public router: Router, public titleService: Title, public sidenavService: SidenavService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-34569558-5', {
          page_path: event.urlAfterRedirects,
        });
      }
    });

    this.sidenavService.activeSubmenu$.subscribe((activeSubmenu) => {
      this.activeSubmenu = activeSubmenu;
    });
  }

  toggleSubmenu(submenu: Submenu): void {
    if (this.activeSubmenu === submenu) {
      this.sidenavService.setActiveSubmenu(undefined);
    } else {
      this.sidenavService.setActiveSubmenu(submenu);
    }
  }
}
