<mat-sidenav-container class="h-100" autosize>
  <mat-sidenav class="sidenav" mode="side" opened="true">
    <mat-nav-list>
      <a mat-list-item [routerLink]="['']" class="parent"> Accueil </a>

      <a mat-list-item (click)="toggleSubmenu(SUBMENU_CPLC_REF)" [routerLink]="['cplc']" class="parent">
        Le CPLC
        <div matListItemMeta>
          <mat-icon matListItemIcon class="menu-button" [ngClass]="{ rotated: activeSubmenu === SUBMENU_CPLC_REF }"
            >expand_more</mat-icon
          >
        </div>
      </a>
      <div
        class="submenu"
        [ngClass]="{ expanded: activeSubmenu === SUBMENU_CPLC_REF }"
        *ngIf="activeSubmenu === SUBMENU_CPLC_REF"
      >
        <a mat-list-item [routerLink]="['cplc', 'historique']">Historique</a>
        <a mat-list-item [routerLink]="['cplc', 'directeur']">Le directeur</a>
        <a mat-list-item [routerLink]="['cplc', 'stage-annuel']">Stage annuel</a>
      </div>

      <a mat-list-item (click)="toggleSubmenu(SUBMENU_ARTISTES_REF)" [routerLink]="['artistes']" class="parent">
        Artistes
        <div matListItemMeta>
          <mat-icon matListItemIcon class="menu-button" [ngClass]="{ rotated: activeSubmenu === SUBMENU_ARTISTES_REF }"
            >expand_more</mat-icon
          >
        </div>
      </a>
      <div
        class="submenu"
        [ngClass]="{ expanded: activeSubmenu === SUBMENU_ARTISTES_REF }"
        *ngIf="activeSubmenu === SUBMENU_ARTISTES_REF"
      >
        <a mat-list-item [routerLink]="['artistes', 'musiciens']">Musiciens</a>
        <a mat-list-item [routerLink]="['artistes', 'solistes']">Solistes</a>
      </div>

      <a mat-list-item (click)="toggleSubmenu(SUBMENU_CONCERTS_REF)" [routerLink]="['concerts']" class="parent">
        Concerts
        <div matListItemMeta>
          <mat-icon matListItemIcon class="menu-button" [ngClass]="{ rotated: activeSubmenu === SUBMENU_CONCERTS_REF }"
            >expand_more</mat-icon
          >
        </div>
      </a>
      <div
        class="submenu"
        [ngClass]="{ expanded: activeSubmenu === SUBMENU_CONCERTS_REF }"
        *ngIf="activeSubmenu === SUBMENU_CONCERTS_REF"
      >
        <a mat-list-item [routerLink]="['concerts', 'prochain-concert']">Prochain concert</a>
        <a mat-list-item [routerLink]="['concerts', 'historique']">Historique</a>
      </div>

      <a mat-list-item (click)="toggleSubmenu(SUBMENU_VINGTIEME_REF)" [routerLink]="['20eme']" class="parent">
        20ème anniversaire
        <div matListItemMeta>
          <mat-icon matListItemIcon class="menu-button" [ngClass]="{ rotated: activeSubmenu === SUBMENU_VINGTIEME_REF }"
            >expand_more</mat-icon
          >
        </div>
      </a>
      <div
        class="submenu"
        [ngClass]="{ expanded: activeSubmenu === SUBMENU_VINGTIEME_REF }"
        *ngIf="activeSubmenu === SUBMENU_VINGTIEME_REF"
      >
        <a mat-list-item [routerLink]="['20eme', '20eme']">20ème anniversaire</a>
        <a mat-list-item [routerLink]="['20eme', 'comite']">Comité</a>
        <a mat-list-item [routerLink]="['20eme', 'tchiki-duo']">Le Tchiki Duo</a>
        <a mat-list-item [routerLink]="['20eme', 'ors-kisfaludy']">Örs Kisfaludy</a>
        <a mat-list-item [routerLink]="['20eme', 'compositeurs-arrangeurs']">Compositeurs et arrangeurs</a>
        <a mat-list-item [routerLink]="['20eme', 'soutiens']">Soutiens</a>
      </div>

      <a mat-list-item [routerLink]="['commande-cd']" class="parent"> Commande de CD </a>

      <a mat-list-item [routerLink]="['galerie']" class="parent"> Galerie photo </a>

      <a mat-list-item (click)="toggleSubmenu(SUBMENU_MEMBRES_REF)" [routerLink]="['accès-musiciens']" class="parent">
        Accès musiciens
        <div matListItemMeta>
          <mat-icon matListItemIcon class="menu-button" [ngClass]="{ rotated: activeSubmenu === SUBMENU_MEMBRES_REF }"
            >expand_more</mat-icon
          >
        </div>
      </a>
      <div
        class="submenu"
        [ngClass]="{ expanded: activeSubmenu === SUBMENU_MEMBRES_REF }"
        *ngIf="activeSubmenu === SUBMENU_MEMBRES_REF"
      >
        <a mat-list-item [routerLink]="['accès-musiciens', 'calendrier']">Calendrier</a>
        <a mat-list-item [routerLink]="['accès-musiciens', 'téléchargements']">Téléchargements</a>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <div class="d-flex flex-column h-100">
    <div class="banner w-100 mb-5">
      <h1>{{ titleService.getTitle() }}</h1>
    </div>
    <div class="logo"></div>

    <div class="page-content">
      <router-outlet></router-outlet>
    </div>

    <footer class="text-center">
      <div id="contact">
        <p>
          Claude Meynent<br />
          Creux-du-Mas 9<br />
          1185 Mont-sur-Rolle
        </p>

        <p>
          Tel : +41 79 479 70 01<br />
          Email : <a href="mailto:mey6@bluewin.ch" alt="Email de Claude Meynent">mey6&#64;bluewin.ch</a>
        </p>
      </div>

      <div id="liens_amis">
        <ul>
          <li>
            <a href="https://www.emrolle.ch" alt="Ecole de musique de Rolle" target="_blank"
              >Ecole de musique de Rolle</a
            >
          </li>
          <li>
            <a href="https://www.musicoss.org" alt="Ecole de musique de Cossonay" target="_blank"
              >Ecole de musique de Cossonay</a
            >
          </li>
          <li><a href="https://www.tchikiduo.com" alt="Tchiki Duo Project" target="_blank">Tchiki Duo</a></li>
          <li>
            <a href="https://www.bande-son.ch" alt="Orchestre symphonique Bande-Son" target="_blank"
              >Orchestre symphonique Bande-Son</a
            >
          </li>
          <li>
            <a href="https://www.cmnv.ch" alt="Conservatoire de Musique du Nord Vaudois" target="_blank"
              >Conservatoire de Musique du Nord Vaudois</a
            >
          </li>
        </ul>
      </div>

      <div id="creation">
        <p>
          Création :
          <a href="https://slashnet.ch" title="Site internet de slashnet - Xavier Willemin" target="_blank"
            >slashnet - Xavier Willemin</a
          >
        </p>
      </div>
    </footer>
  </div>
</mat-sidenav-container>
