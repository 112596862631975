import { AccesMusiciensModule } from './acces-musiciens/acces-musiciens.module';
import { GaleriePhotosModule } from './galerie-photos/galerie-photos.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CPLCModule } from './cplc/cplc.module';
import { ArtistesModule } from './artistes/artistes.module';
import { ConcertsModule } from './concerts/concerts.module';
import { CommandeDeCDModule } from './commande-de-cd/commande-de-cd.module';
import { VingtiemeAnniversaireModule } from './vingtieme-anniversaire/vingtieme-anniversaire.module';
import { AccueilModule } from './accueil/accueil.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagesService } from './images.service';
import { ContactService } from './contact.service';
import { SidenavService } from './sidenav.service';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        CPLCModule,
        ArtistesModule,
        ConcertsModule,
        CommandeDeCDModule,
        VingtiemeAnniversaireModule,
        GaleriePhotosModule,
        AccesMusiciensModule,
        AccueilModule,
        BrowserAnimationsModule,
        MatListModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        NgbModule], providers: [ImagesService, ContactService, SidenavService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
